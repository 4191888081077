import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Failure from "./Failure";
import LinkClosed from "./LinkClosed";
import RegistrationForm from "./RegsitrationForm";
import Success from "./Success";
export default function App() {
  return (
    <Router basename="/testregistration">
        <Switch>
          <Route path="/" exact component={RegistrationForm} />
          <Route path="/success" exact component={Success} />
          <Route path="/fail" exact component={Failure} />
          <Route path="/closed" exact component={LinkClosed}/> 
        </Switch>
    </Router>
  );
}
