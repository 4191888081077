import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  Form,
  Row,
  Input,
  Button,
  Radio,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
export default function RegistrationForm() {
  const cardStyle = {
    borderRadius: "16px",
    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [config, setConfig] = useState();
  const url = "https://suapi.pointcooktutoring.com/api/";
  useEffect(() => {
    fetchConfig();
  }, []);
  const fetchConfig = async () => {
    const response = await fetch(url + "fetchConfigurations");
    const data = await response.json();
    if (data.details.TEST_REGISTRATION_LINK_UP === false) {
      window.location.href = "/closed";
    } else {
      setConfig(data.details);
      localStorage.setItem("config", JSON.stringify(data.details));
      setInitialLoading(false);
    }
  };
  const submit = async (values) => {
    // if (!values.agreement) {
    //   setError(
    //     "Please check to confirm that you understand that these tests are chargeable"
    //   );
    //   return;
    // }
    setLoading(true);
    let newValues = { ...values, infosession: true };
    let formData = new FormData();
    Object.entries(newValues).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await fetch(url + "createSession", {
      body: formData,
      method: "POST",
    });
    const data = await response.json();
    setLoading(false);
    if (data.success) {
      window.location.href = `/success?session_id=${data.details}`;
    }
  };
  const { lg } = useBreakpoint();
  return (
    !initialLoading && config && (
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} offset={lg ? 6 : 0}>
          <div style={{ height: 16 }} />
          <Card bordered={false} style={cardStyle}>
            <Card.Meta
              title="POINT COOK TUTORING"
              description={`${config.TEST_REGISTRATION_TERM_NAME}`}
            />
            <div style={{ height: 32 }} />
            {error ? <strong style={{ color: "red" }}>{error}</strong> : null}
            {/* <strong>Sorry Term 3 test registrations are now closed.</strong>
          <br />
          <br />
          <strong>Thank you. See you back at the end of Term 3.</strong> */}
            <Form layout="vertical" onFinish={submit}>
              <Form.Item
                label="Student FULL Name"
                name="student_name"
                rules={[
                  { required: true, message: "Please enter students name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Student Grade"
                name="student_grade"
                rules={[
                  { required: true, message: "Please select students grade!" },
                ]}
              >
                <Radio.Group>
                  {JSON.parse(config.TEST_REGISTRATION_GRADES).map(x=>(
                    <Radio value={x} key={x}>{x}</Radio>
                  ))}
                  
                  {/* <Radio value={3}>3</Radio>
                  <Radio value={4}>4</Radio>
                  <Radio value={5}>5</Radio>
                  <Radio value={6}>6</Radio> */}
                  {/* <Radio value={"SS7"}>Select School 7</Radio> */}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Parent FULL Name"
                name="parent_name"
                rules={[
                  { required: true, message: "Please enter parents name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Parent Contact Number" name="contact_number">
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label="Parent E-Mail"
                name="parent_email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                label="Test Format (Choose ONLY one test format.)"
                name="delivery"
                rules={[
                  { required: true, message: "Please select test format!" },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <div style={{ paddingLeft: 8 }}>
                      <label style={{ fontWeight: "bold" }}>{`At POINT COOK TUTORING`}</label>
                      <div><label style={{ fontWeight: "bold" }}> Unit 29, 22-30 Wallace Avenue, Point Cook 3030</label></div>
                    </div>
                    <Radio
                      value={config.TEST_REGISTRATION_OPTION_1}
                    >
                      {config.TEST_REGISTRATION_OPTION_1}
                    </Radio>
                    <Radio
                      value={config.TEST_REGISTRATION_OPTION_2}
                    >
                      {config.TEST_REGISTRATION_OPTION_2}
                    </Radio>
                    <label style={{ paddingLeft: 8, fontWeight: "bold" }}>At Home</label>
                    <Radio value={"Email PDF"}>
                      Email PDF format - I have access to printer and will print
                      it myself.
                    </Radio>
                    <Radio value={"Collect"}>
                      PAPER format - I will come to collect the test paper.
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {/* <Form.Item label="" name="agreement" valuePropName="checked">
                <Checkbox>
                  I understand there is a $50 charge for these tests
                </Checkbox>
              </Form.Item> */}
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  );
}
