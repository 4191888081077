import { Card, Col, Row, Spin } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function Success() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  let query = useQuery();
  const config = JSON.parse(localStorage.getItem("config"))
  useEffect(() => {
    finishTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const finishTransaction = async () => {
    let formData = new FormData();
    formData.append("session_id", query.get("session_id"));
    const url = "https://suapi.pointcooktutoring.com/api/updateSession";
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let data = await response.json();
    setData(data.details);
    setLoading(false);
  };
  const cardStyle = {
    borderRadius: "16px",
    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
  };
  const { lg } = useBreakpoint();
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} offset={lg ? 6 : 0}>
        <div style={{ height: 16 }} />
        <Card bordered={false} style={cardStyle}>
          <Card.Meta
            title="POINT COOK TUTORING"
            description={`${config.TEST_REGISTRATION_TERM_NAME} - Test Registration`}
          />
          <div style={{ height: 16 }} />
          {loading ? (
            <Spin />
          ) : (
            <div>
              <p>
                Thank you {data.parent_name}. You have successfully registered{" "}
                {data.student_name} for the {config.TEST_REGISTRATION_TERM_NAME} Tests @ Point Cook Tutoring.
              </p>
              <b>
                Test Format:
                <p><span dangerouslySetInnerHTML={{ __html: data.delivery }}></span></p>
              </b>
              <p>
                For further assistance you can email us at{" "}
                <a href="mailto:info@pointcooktutoring.com">
                  info@pointcooktutoring.com.
                </a>
              </p>
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
}
