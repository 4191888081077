import { Card, Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { Link } from "react-router-dom";

export default function Failure() {
  const cardStyle = {
    borderRadius: "16px",
    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
  };
  const { lg } = useBreakpoint();
  const config = JSON.parse(localStorage.getItem("config"))
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} offset={lg ? 6 : 0}>
        <div style={{ height: 16 }} />
        <Card bordered={false} style={cardStyle}>
          <Card.Meta
            title="Point Cook Tutoring"
            description={`${config.TEST_REGISTRATION_TERM_NAME} - Test Registration`}
          />
          <div style={{ height: 16 }} />
          <div>
            <p>
              Payment Failed. Please check your details and <Link to="/">try again</Link>
            </p>
            <p>
              For further assistance you can email us at{" "}
              <a href="mailto:info@pointcooktutoring.com">
                info@pointcooktutoring.com
              </a>
            </p>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
